<template>
  <header class="tpl-header">
    <div
      class="tpl-cover"
      :style="{
        backgroundImage: template.cover ? `url(${template.cover})` : null
      }"
    ></div>
    <div class="tpl-info">
      <div class="title-wrapper">
        <div class="title">{{ template.name }}</div>
        <a @click="$listeners.return">
          返回模板中心 <i class="ndl-icon-chevrons-right"></i>
        </a>
      </div>
      <div class="description">{{ template.description }}</div>
      <div>
        <a-button type="primary" :loading="loading" @click="use()">
          使用此模板
        </a-button>
      </div>
    </div>
    <Modal
      :config="config"
      @return="$listeners.return"
      @redirect="onRedirect"
    />
  </header>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import { gotoWorkspace } from "@/views/datamodel/components/aside/src/node-options";
import * as API from "@/api/template";
import * as utils from "./utils";
import Modal from "./Modal";

export default {
  components: { Modal },
  props: {
    template: Object
  },
  data() {
    return {
      loading: false,
      config: {
        visible: false,
        status: "progress",
        message: null
      }
    };
  },
  created() {
    console.log(this.template);
  },
  methods: {
    async use() {
      this.loading = true;
      const config = this.config;
      try {
        await utils.validate(this.template);
        // 显示 modal
        config.visible = true;
        config.status = "progress";
        config.message = null;
        const { data } = await API.useTemplate(this.template.id);
        config.status = "success";
        this.node = workspace.addChild(data);
      } catch (err) {
        const data = err.data || {};
        config.status = "failed";
        config.message = data.msg ? `${data.msg}（0x${data.code}）` : null;
      } finally {
        this.loading = false;
      }
    },
    onRedirect() {
      if (this.node) {
        gotoWorkspace(this.node);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tpl-header {
  display: flex;
  align-items: flex-start;
  max-width: 1800px;
  .tpl-cover {
    @size: 240px;
    width: @size;
    height: @size;
    flex-shrink: 0;
    margin-right: 36px;
    background: url(/images/default_cover5.jpg) center / cover no-repeat;
    border-radius: 8px;
  }
  .tpl-info {
    flex: 1 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .title-wrapper {
    display: flex;
  }
  .title {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    font-weight: 500;
  }
  .description {
    margin: 1em 0;
    color: #545454;
  }
  .ant-btn {
    border-radius: 2px;
  }
}
</style>
