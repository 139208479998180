import Vue from "vue";
import * as API from "@/api/connections";
import { Types, localeTypes, sortDefinitions, keepState } from "./helper";

class DefinitionList {
  constructor() {
    this.definitions = [];
    this.loading = false;
    this.init();
  }

  get sources() {
    return this.definitions.filter(item => item.category === Types.SOURCE);
  }

  get destinations() {
    return this.definitions.filter(item => item.category === Types.DESTINATION);
  }

  /**
   * 初始化 definitions 列表，一般只会在进入应用时初始化一次
   * 随后需要更新数据可调用 reloadDefinitions() 方法
   * @returns {Promise<any>}
   */
  init = keepState(function() {
    this.loading = true;
    return this.reloadDefinitions().finally(() => {
      this.loading = false;
    });
  }, true);

  async reloadDefinitions() {
    const { data } = await API.getDefinitions([
      Types.SOURCE,
      Types.DESTINATION
    ]);
    this.definitions = sortDefinitions(data);
    for (const item of data) {
      item.icon = item.icon || "/images/default_connector_icon.svg";
    }
  }
  sort() {
    this.definitions = sortDefinitions(this.definitions);
  }
  getDefinition(id) {
    return this.definitions.find(item => item.id == id);
  }
  async saveDefinition(definitionConfig) {
    if (!definitionConfig) {
      return;
    }
    const { data } = await API.saveDefinition(definitionConfig);
    const definition = this.getDefinition(definitionConfig.id);
    if (!definition) {
      data.category = definitionConfig.category;
      this.definitions.push(data);
    } else {
      Object.assign(definition, data);
    }
  }
  async deleteDefinition(definitionId) {
    const category = this.getCategory(definitionId);
    await API.deleteDefinition({ category, id: definitionId });
    this.definitions = this.definitions.filter(
      item => item.id !== definitionId
    );
  }
  getName(id) {
    const def = this.getDefinition(id);
    return def ? def.name : null;
  }
  getIcon(id) {
    const def = this.getDefinition(id);
    return def && def.icon;
  }
  getCategory(id) {
    const def = this.getDefinition(id);
    return def ? def.category : null;
  }
  count(category) {
    if (!category) {
      return this.definitions.length;
    }
    return this.definitions.filter(item => item.category == category).length;
  }
  async getSpecification(definitionId, category) {
    if (!category) {
      await this.init();
      category = this.getCategory(definitionId);
    }
    const { data } = await API.getSpecification({ definitionId, category });
    return data;
  }
}

export const definitionList = Vue.observable(new DefinitionList());

export { Types, localeTypes };

export default definitionList;
