<template>
  <Exhibit
    style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 60px 0"
    @click="onCreate()"
  >
    <img
      src="@/assets/images/ai_no_data2.svg"
      style="width: 150px; margin-bottom: 36px"
    />
    <a-button
      icon="plus"
      type="primary"
      style="border-radius: 2px"
      :loading="loading"
    >
      立即创建空白项目
    </a-button>
  </Exhibit>
</template>

<script>
import * as API from "@/api/node";
import workspace, { ModelTree } from "@/views/datamodel/models/workspace";
import Exhibit from "./Exhibit";

export default {
  components: { Exhibit },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async onCreate() {
      this.loading = true;
      const payload = {
        parentId: workspace.nodeId,
        nodeName: "新建项目",
        nodeType: ModelTree.NODE_FOLDER
      };
      const { data } = await API.createNode(payload);
      data.children = [];
      const node = workspace.addChild(data);
      node.nodeStatus.renamingAtHeader = true;
      this.$router.push({
        name: "datamodel-workspace-guide",
        params: { workspaceId: data.nodeId }
      });
      this.loading = false;
    }
  }
};
</script>
