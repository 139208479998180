<template>
  <div v-if="loading" class="editor-wrapper">
    <a-skeleton active />
  </div>
  <JSONSchema
    v-else
    v-model="connector.connectionConfiguration"
    ref="jsonSchema"
    class="editor-wrapper ndl-antd"
    :specification="specification"
  >
    <a-form-item
      slot="header"
      label="连接器名称"
      required
      style="order: -1"
      help="输入一个名称来帮助您识别此连接器"
      :validate-status="connectorNameValidateStatus"
    >
      <a-input
        v-model="connector.name"
        @change="validateConnectorName($event.target.value)"
      />
    </a-form-item>
    <div slot="footer" class="footer">
      <slot></slot>
    </div>
  </JSONSchema>
</template>

<script>
import JSONSchema from "@/components/json-schema-ui";
import definitionList from "@/views/connections/models/definitions";
import localeSpecification from "@/views/connections/mock/specifications";

export default {
  components: { JSONSchema },
  props: {
    definitionId: String,
    category: String,
    value: Object
  },
  data() {
    return {
      loading: true,
      specification: {},
      connector: {},
      connectorNameValidateStatus: "success"
    };
  },
  created() {
    this.resetConnector();
    this.getSpecification();
  },
  watch: {
    definitionId() {
      this.getSpecification();
    },
    value(newValue) {
      if (newValue !== this.connector) {
        this.resetConnector();
      }
    },
    connector(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    resetConnector() {
      this.connector = Object.assign(
        {
          connectionConfiguration: {},
          name: ""
        },
        this.value
      );
    },
    validateConnectorName(value) {
      this.connectorNameValidateStatus = value ? "success" : "error";
      return !!value;
    },
    async getSpecification() {
      if (!this.definitionId) {
        return;
      }
      this.loading = true;
      try {
        const data = await definitionList.getSpecification(
          this.definitionId,
          this.category
        );
        const spec = localeSpecification(this.definitionId, data);
        this.specification = spec.connectionSpecification;
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const isNameValid = this.validateConnectorName(this.connector.name);
      const jsonSchemaValid = this.$refs.jsonSchema.validate();
      // 校验失败滚动到第一个失败表单项
      // 校验通过 errorField 基本上为 null，因此不做多余判断
      this.$nextTick(() => {
        const errorField = document.querySelector(".has-error");
        if (errorField) {
          errorField.scrollIntoView({ behavior: "smooth" });
        }
      });
      return isNameValid && jsonSchemaValid;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.editor-wrapper {
  max-width: 680px;
  margin: 36px auto;
  padding: 0 1.3em;
  display: flex;
  flex-direction: column;
  /deep/ .ant-form-item-with-help {
    margin-bottom: 48px;
  }
  /deep/ label,
  /deep/ .custom-label {
    color: @local-title-color;
  }
  /deep/ .ant-form-item,
  /deep/ .footer {
    order: 10000;
  }
}
</style>
