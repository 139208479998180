<template>
  <div class="apps">
    <App
      v-for="item in apps"
      :key="item.definitionId"
      :app="item"
      @selected="$listeners.selected"
    />
  </div>
</template>

<script>
import App from "./App";

export default {
  components: { App },
  props: {
    apps: Array
  }
};
</script>

<style lang="less" scoped>
.apps {
  display: flex;
  flex-wrap: wrap;
}
</style>
