<template>
  <div class="nodes">
    <div v-for="(item, index) in nodes" :key="index" class="node">
      <Iconfont :type="item.icon || defaultIcon" />
      <span class="label" :title="item.name">{{ item.name }}</span>
    </div>
    <div class="placeholder"></div>
    <div class="placeholder"></div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import Iconfont from "@/components/iconfont";

export default {
  components: { Iconfont },
  props: {
    nodes: Array,
    defaultIcon: String
  }
};
</script>

<style lang="less" scoped>
.nodes {
  display: flex;
  flex-wrap: wrap;
  max-width: 1500px;
  .node,
  .placeholder {
    flex: 1 1 0;
    min-width: 280px;
    padding: 0 12px;
    margin: 0 28px 18px 0;
  }
  .node {
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px,
      rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
  }
  .icon-symbol {
    font-size: 20px;
    margin-right: 6px;
  }
  .label {
    flex: 1 1 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
