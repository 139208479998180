<template>
  <keep-alive include="TemplateList">
    <TemplateDetail v-if="$route.query.id" @return="onUnset()" />
    <TemplateList v-else @select="onSelect" />
  </keep-alive>
</template>

<script>
import { TemplateList, TemplateDetail } from "./components/template";

export default {
  components: { TemplateList, TemplateDetail },
  methods: {
    onSelect(item) {
      this.$router.replace({
        name: "datamodel-welcome",
        query: { id: item.id }
      });
    },
    onUnset() {
      this.$router.replace({ name: "datamodel-welcome" });
    }
  }
};
</script>
