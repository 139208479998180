<template>
  <div style="padding: 36px">
    <Banner @search="keyword = $event" />
    <Gallery :filter-word="keyword" @select="$listeners.select" />
  </div>
</template>

<script>
import Banner from "./banner";
import Gallery from "./gallery";

export default {
  components: { Banner, Gallery },
  data() {
    return {
      keyword: null
    };
  }
};
</script>
