<template>
  <a-modal
    width="720px"
    centered
    :title="`应用授权「${appName}」`"
    :confirm-loading="loading"
    :body-style="{ maxHeight: 'calc(100vh - 150px)', overflow: 'auto' }"
    @ok="onConfirm()"
  >
    <ConnectorEditor
      v-model="app"
      ref="editor"
      category="source"
      :definition-id="definitionId"
    />
  </a-modal>
</template>

<script>
import ConnectorEditor from "@/views/connections/components/connector-editor";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ConnectorEditor },
  props: {
    templateId: String,
    definitionId: String,
    appName: String,
    $resolve: Function
  },
  data() {
    return {
      loading: false,
      app: { name: this.appName }
    };
  },
  methods: {
    async onConfirm() {
      if (!this.$refs.editor.validate()) {
        return;
      }
      try {
        this.loading = true;
        const connector = await connectorList.saveConnector(
          {
            ...this.app,
            category: "source",
            definitionId: this.definitionId,
            templateId: this.templateId
          },
          true
        );
        this.$resolve(connector);
      } catch (err) {
        const resData = err.data || {};
        this.$notification.error({
          message: "操作失败",
          description: resData.msg
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
