<template>
  <div class="group-label">
    <div class="label">{{ label }}</div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    description: String
  }
};
</script>

<style lang="less" scoped>
.group-label {
  margin: 60px 0 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 1em;
  }
  .description {
    color: #bfbfbf;
  }
}
</style>
