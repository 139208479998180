import { Modal, Message } from "ant-design-vue";
import auth from "../app-authorization";

export function confirm(opts) {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      ...opts,
      onCancel: () => {
        opts.onCancel && opts.onCancel();
        reject();
      },
      onOk: () => {
        opts.onOk && opts.onOk();
        resolve();
      }
    });
  });
}

export function select(connector) {
  const config = {
    content: `「${connector.name}」是必选的`,
    key: "select-template-connector"
  };
  // 已经授权的连接器不能取消选择
  if (connector.authorized) {
    Message.warning(config);
    return true;
  }
  // 未授权的连接器，但是必选的不能取消选择
  if (connector.optional) {
    Message.warning(config);
    return true;
  }
  // 未必选，就取反然后返回
  return !connector.selected;
}

export async function validate(template) {
  const apps = (template.connectors || []).filter(
    item => !item.authorized && item.selected
  );
  if (apps.length) {
    await confirm({
      title: "使用此模板",
      content: "此模板所需的部分应用未授权，点击“继续”完成授权",
      okText: "继续"
    });
  }
  for (const item of apps) {
    if (!item.authorized) {
      await auth({ definitionId: item.definitionId, appName: item.name });
      item.authorized = true;
    }
  }
}
