<template>
  <div class="gallery">
    <ExhibitCustom />
    <ExhibitPreset
      v-for="item in filteredList"
      :key="item.id"
      :preset="item"
      @select="$listeners.select"
    />
    <ExhibitMock v-for="item in filteredMock" :key="item.id" :preset="item" />
    <!-- 每行 3 个，故保留 2 个 placholder -->
    <div class="placeholder"></div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import * as API from "@/api/template";
import ExhibitCustom from "./ExhibitCustom";
import ExhibitPreset from "./ExhibitPreset";
import ExhibitMock from "./ExhibitMock";
import mock from "./mock";

function filter(keyword, list) {
  const word = String(keyword || "").toLowerCase();
  if (!word) {
    return list;
  }
  return list.filter(item =>
    String(item.name || "")
      .toLowerCase()
      .includes(word)
  );
}

export default {
  components: { ExhibitCustom, ExhibitPreset, ExhibitMock },
  props: {
    filterWord: String
  },
  data() {
    return {
      mock,
      list: []
    };
  },
  computed: {
    filteredList() {
      return filter(this.filterWord, this.list);
    },
    filteredMock() {
      return filter(this.filterWord, this.mock);
    }
  },
  async created() {
    const { data } = await API.getTemplateList();
    this.list = data;
  }
};
</script>

<style lang="less" scoped>
@gutter: 1.2em;

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1100px;
  margin: 0 auto;

  .exhibit,
  .placeholder {
    width: 260px;
    flex: 1 1 auto;
    max-width: 280px;
  }

  .placeholder {
    margin: 0 @gutter;
  }
  .exhibit {
    margin: @gutter;
  }
}
</style>
