<template>
  <Exhibit @click="$emit('select', preset)">
    <div
      class="cover"
      :style="{ backgroundImage: preset.cover ? `url(${preset.cover})` : null }"
    ></div>
    <div class="title">{{ preset.name }}</div>
    <div class="description">{{ preset.description }}</div>
    <APPLibrary :apps="preset.connectors" />
  </Exhibit>
</template>

<script>
import Exhibit from "./Exhibit";
import APPLibrary from "./AppLibrary";

export default {
  components: { Exhibit, APPLibrary },
  props: {
    preset: Object
  }
};
</script>

<style lang="less" scoped>
@edge: 12px;

.exhibit {
  .cover {
    // PM 说封面图片要 4:3，yue
    padding-bottom: 75%;
    background: url(/images/default_cover5.jpg) center / cover no-repeat;
  }
  .title {
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    margin: 0 @edge;
  }
  .description {
    height: 44px;
    margin: 0 @edge;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #545454;
  }
  .apps {
    padding: @edge;
    color: #545454;
  }
}
</style>
