<template>
  <Exhibit @click="$emit('select', preset)">
    <div class="cover"></div>
    <div class="title">{{ preset.name }}</div>
    <div class="description">{{ preset.description }}</div>
    <div class="footer">
      <i class="ndl-icon-info"></i>
      <span>即将上线，敬请期待～</span>
    </div>
  </Exhibit>
</template>

<script>
import Exhibit from "./Exhibit";

export default {
  components: { Exhibit },
  props: {
    preset: Object
  }
};
</script>

<style lang="less" scoped>
@edge: 12px;

.exhibit {
  .cover {
    padding-bottom: 75%;
    background: url(~@/assets/images/nexadata_logo.svg) center / 50% no-repeat;
  }
  .title {
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    margin: 0 @edge;
  }
  .description {
    height: 44px;
    margin: 0 @edge;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #545454;
  }
  .footer {
    padding: @edge;
    color: #bfbfbf;
    i {
      margin-right: 8px;
    }
  }
}
</style>
