<template>
  <div @click="() => $emit('selected', app)" class="app ndl-cursor-pointer">
    <img :src="app.icon || '/images/default_connector_icon.svg'" class="icon" />
    <span class="info">
      <span class="title">{{ app.name }}</span>
      <span class="status">
        <a-tag v-if="app.authorized" color="#87d068">已授权</a-tag>
        <a-tag v-else color="#108ee9" @click.stop="auth()">请授权</a-tag>
      </span>
    </span>
    <div :class="['select', app.selected ? 'selected' : 'no-select']">
      <a-icon type="check" class="select-icon" />
    </div>
  </div>
</template>

<script>
import auth from "../app-authorization";

export default {
  props: {
    app: Object
  },
  methods: {
    async auth() {
      const appName = this.app.name;
      const definitionId = this.app.definitionId;
      const templateId = this.$route.query.id;
      await auth({ templateId, definitionId, appName });
      this.app.authorized = true;
      this.app.selected = true;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";
@size: 46px;

.app {
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 12px;
  width: 250px;
  margin: 0 28px 18px 0;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px,
    rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
  .icon {
    width: @size;
    height: @size;
  }
  .info {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    overflow: hidden;
  }
  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .status {
    margin-top: 4px;
  }
  .ant-tag {
    border-radius: 2px;
    cursor: pointer;
  }
  .select {
    width: 24px;
    height: 24px;
    padding: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    .select-icon {
      font-size: 12px;
      color: #ffffff;
      transform: scale(0.6);
    }
  }
  .selected {
    background-image: linear-gradient(
      to top right,
      #ffffff,
      #ffffff 50%,
      @blue-6 50%,
      @blue-6
    );
  }
  .no-select {
    background-image: linear-gradient(
      to top right,
      #ffffff,
      #ffffff 50%,
      #dddddd 50%,
      #dddddd
    );
  }
}
</style>
