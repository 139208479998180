export default [
  "电商库存分析",
  "电商经营分析",
  "用户客群分析",
  "用户复购分析",
  "获客渠道质量评估",
  "毛利分析",
  "营销活动效果分析",
  "广告投放分析",
  "直播间运营分析"
].map((name, index) => ({
  name,
  id: "mock_" + index,
  description: "下秒数据官方分析模板"
}));
