import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=9ed6d254&scoped=true&"
var script = {}
import style0 from "./Banner.vue?vue&type=style&index=0&id=9ed6d254&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ed6d254",
  null
  
)

export default component.exports