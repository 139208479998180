<template>
  <div class="apps">
    <a-tooltip v-for="item in apps" :key="item.definitionId" :title="item.name">
      <img :src="item.icon || '/images/default_connector_icon.svg'" />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    apps: Array
  }
};
</script>

<style lang="less" scoped>
@size: 30px;

.apps {
  display: flex;
  overflow: hidden;
  img {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    width: @size;
    height: @size;
    border-radius: 4px;
  }
}
</style>
