<template>
  <a-button v-bind="$attrs" v-on="$listeners">
    <slot></slot>({{ count }})
  </a-button>
</template>

<script>
export default {
  data() {
    return {
      count: 5
    };
  },
  mounted() {
    const timer = setInterval(() => {
      this.count -= 1;
      if (!this.count) {
        clearInterval(timer);
        this.$emit("click");
      }
    }, 1000);
  }
};
</script>
