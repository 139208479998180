<script>
import { ModelTree } from "@/views/datamodel/components/tree";
import GroupLabel from "./GroupLabel";
import Apps from "./Apps";
import Nodes from "./Nodes";

export default {
  props: {
    template: Object
  },
  render() {
    /**
     * @type {{
     *  id: string;
     *  name: string;
     *  description: string;
     *  cover: string;
     *  connectors: [];
     *  datasheets: [];
     *  dashboards: [];
     * }}
     */
    const template = this.template || {};
    const onSelected = this.$listeners.selected || (() => {});
    return (
      <div style="margin-top: 100px">
        <GroupLabel label="数据源" description="使用此模板需授权以下连接器" />
        <Apps apps={template.connectors} onSelected={onSelected} />
        <GroupLabel label="此模板生成的视图" />
        <Nodes
          nodes={template.datasheets}
          default-icon={ModelTree.ICON_DATASHEET}
        />
        <GroupLabel label="此模板生成的看板" />
        <Nodes
          nodes={template.dashboards}
          default-icon={ModelTree.ICON_DASHBOARD}
        />
      </div>
    );
  }
};
</script>
