import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/template") });

interceptor.auth(axios);

export function getTemplateList() {
  return axios.get("list");
}

export function getTemplate(id) {
  return axios.get(id);
}

export function useTemplate(id) {
  return axios.post("apply/" + id, { id });
}
