<template>
  <div style="padding: 36px">
    <a-skeleton
      v-if="loading"
      active
      :avatar="{ shape: 'square', size: 240 }"
    />
    <DetailHeader
      v-if="!loading"
      :template="data"
      @return="$listeners.return"
    />
    <DetailBody v-if="!loading" :template="data" @selected="onSelected" />
  </div>
</template>

<script>
import * as API from "@/api/template";
import DetailHeader from "./detail-header";
import DetailBody from "./detail-body";
import { select } from "./detail-header/utils";

export default {
  components: { DetailHeader, DetailBody },
  data() {
    return {
      loading: true,
      data: {}
    };
  },
  async created() {
    try {
      const templateId = this.$route.query.id;
      const { data } = await API.getTemplate(templateId);
      // data.connectors = [].concat(data.connectors, [
      //   {
      //     definitionId: "26e93d2f-0fd8-4359-a2a7-1045e6e63885",
      //     name: "维格表",
      //     icon:
      //       "https://test.nexadata.cn/api/attach/9aea7debf2620872ae5ded6b5ffd333b",
      //     authorized: false,
      //     optional: false
      //   },
      //   {
      //     definitionId: "e0d33e30-e9c9-4924-9f60-9e22f68e77bd",
      //     name: "小红书",
      //     icon:
      //       "https://test.nexadata.cn/api/attach/d38ffe45fcf6e2147e64ee28e3ddf1c4",
      //     authorized: false,
      //     optional: true
      //   }
      // ]);
      // 连接器默认全选
      data.connectors.forEach(item => {
        item.selected = true;
      });
      this.data = data;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onSelected(app) {
      app.selected = select(app);
    }
  }
};
</script>
