import sourceMysql from "./source.mysql.json";
import sourcePostgres from "./source.postgres.json";
import sourceOracle from "./source.oracle.json";
import sourceAirtable from "./source.airtable.json";
import sourceMssql from "./source.mssql.json";
import sourceDb2 from "./source.db2.json";
import sourceMongodb from "./source.mongodb.json";
import destMysql from "./destination.mysql.json";
import destPostgres from "./destination.postgres.json";
import destOracle from "./destination.oracle.json";
import destMssql from "./destination.mssql.json";
import destElasticsearch from "./destination.elasticsearch.json";
import destMongodb from "./destination.mongodb.json";
import destClickhouse from "./destination.clickhouse.json";

export default function(definitionId, fallbackSpec) {
  const map = {
    "435bb9a5-7887-4809-aa58-28c27df0d7ad": sourceMysql,
    "decd338e-5647-4c0b-adf4-da0e75f5a750": sourcePostgres,
    "b39a7370-74c3-45a6-ac3a-380d48520a83": sourceOracle,
    "14c6e7ea-97ed-4f5e-a7b5-25e9a80b8212": sourceAirtable,
    "b5ea17b1-f170-46dc-bc31-cc744ca984c1": sourceMssql,
    "447e0381-3780-4b46-bb62-00a4e3c8b8e2": sourceDb2,
    "b2e713cd-cc36-4c0a-b5bd-b47cb8a0561e": sourceMongodb,
    "ca81ee7c-3163-4246-af40-094cc31e5e42": destMysql,
    "25c5221d-dce2-4163-ade9-739ef790f503": destPostgres,
    "3986776d-2319-4de9-8af8-db14c0996e72": destOracle,
    "d4353156-9217-4cad-8dd7-c108fd4f74cf": destMssql,
    "68f351a7-2745-4bef-ad7f-996b8e51bb8c": destElasticsearch,
    "8b746512-8c2e-6ac1-4adc-b59faafd473c": destMongodb,
    "ce0d828e-1dc4-496c-b122-2da42e637e48": destClickhouse
  };
  return map[definitionId] || fallbackSpec;
}
